.mainContainer{
  margin-top: 3%;
  width: 50%;
}
  
#footer{
  width:100%;
  position:absolute;
  bottom:0px;
  text-align:center;
  height: 40px;
  background-color: #000;
  color: #fff;
}
